import { Camera } from "expo-camera";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  FlatList,
  ImageBackground,
  Modal,
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
} from "react-native";

const chromaKeyOut = (red, green, blue, threshold) => {
  const max = Math.max(red, green, blue);
  if (max !== green) return false;

  const mid = Math.max(red, blue);
  return max - mid > threshold;
}


const applyChromaKey = (context, width, height) => {
  let imageData = context.getImageData(0, 0, width, height);
  let data = imageData.data;
  const threshold = 20
  let counter = 0
  for(var i = 0, n = data.length; i < n; i += 4) {
    const red = data[i]
    const green = data[i + 1]
    const blue = data[i + 2]
    if (chromaKeyOut(red, green, blue, threshold)) {
      counter++
      data[i + 3] = 0;
    }
  }
  context.putImageData(imageData, 0, 0);
  return counter > 100
}

const flipImage = (context, width) => {
  context.translate(width, 0);
  context.scale(-1, 1);
}

  const data = [
  {
    id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
    title: 'Pumpkins',
    src: require("./assets/pumpkins.png")
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    title: 'Ghosts',
    src: require('./assets/ghosts.png')
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e29d72',
    title: 'DNA',
    src: require('./assets/dna.png')
  },
  {
    id: '3885c131-747f-4a1f-9f56-48baf4efdab9',
    title: 'Skeletons',
    src: require("./assets/skeletons.png")
  },
  {
    id: 'c99f6c53-c0c0-476e-b9b9-8c64763973a3',
    title: 'Werewolves',
    src: require('./assets/werewolves.png')
  },
  {
    id: 'ea8a4335-be34-4ee7-9f91-90d140cf15c4',
    title: 'Candy',
    src: require('./assets/candy.png')
  },
];

export default function App() {
  const [status, requestPermission] = Camera.useCameraPermissions();
  const [type, setType] = useState(Camera.Constants.Type.back);
  const [lastPhotoURI, setLastPhotoURI] = useState(null);
  const [costumeSrc, setCostumeSrc] = useState(data[0].src)
  const canvasRef = useRef(null)
  const [modalVisible, setModalVisible] = useState(false);
  const [didApplyChromaKey, setDidApplyChromaKey] = useState(false);

  const cameraRef = useRef(null);

  if (!status?.granted) {
    return (
      <View
        style={styles.permissionContainer}
      >
        <Text style={styles.permissionText}>
          This silly, little Halloween app needs to access your camera.
        </Text>
        <Button onPress={requestPermission} buttonsStyle={styles.permissionButton} color="#666" title="Grant permission" />
      </View>
    );
  }

  const updatePhoto = async ({height, uri, width}) => {
    const context = canvasRef.current.getContext('2d')
    context.canvas.width = width
    context.canvas.height = height
    const image = new Image()
    image.width = width
    image.height = height
    image.src = uri
    image.addEventListener('load', async () => {
      if (type === Camera.Constants.Type.front) {
        flipImage(context, width)
      }
      context.drawImage(image, 0, 0, width, height)
      const didApplyChromaKey = applyChromaKey(context, width, height)
      const newURI = await canvasRef.current.toDataURL();
      setLastPhotoURI(newURI)
      setDidApplyChromaKey(didApplyChromaKey)
      setModalVisible(true)
    })
  }

const Item = ({ title, src }) => (
  <TouchableOpacity onPress={() => {
    setCostumeSrc(src)
    setModalVisible(!modalVisible)
  }} style={styles.item}><Text style={styles.itemText}>{title}</Text>
  </TouchableOpacity>
);

const renderItem = ({ item }) => (
  <Item title={item.title} src={item.src} />
);

  if (lastPhotoURI !== null) {
    return (
      <ImageBackground
          source={costumeSrc}
          style={{
            flex: 1,
            backgroundColor: "transparent",
            flexDirection: "row",
            justifyContent: "center",
          }}
      >
        <ImageBackground
          source={{uri: lastPhotoURI}}
          style={{
            flex: 1,
            backgroundColor: "transparent",
            flexDirection: "row",
            justifyContent: "center",
          }}
          >
          {modalVisible ? <View style={styles.centeredView}>
            <Modal
              animationType="slide"
              transparent={true}
              style={styles.modal}
              visible={modalVisible}
              onRequestClose={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <View style={styles.modalView}>
                <FlatList
                  data={data}
                  style={{width: '100%'}}
                  renderItem={renderItem}
                  keyExtractor={item => item.id}
                  >
                </FlatList>
              </View>
            </Modal>
          </View> : null}
          {/* {!modalVisible && !didApplyChromaKey ? <View>There was not enough green in this photo! Try again...</View> : null} */}
          {!modalVisible ? <TouchableOpacity
            style={{
              flex: 0.2,
              alignSelf: "flex-end",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#666",
              marginBottom: 40,
              marginLeft: 20,
            }}
            onPress={() => {
              setLastPhotoURI(null);
            }}
            >
            <Text style={{ fontSize: 30, padding: 10, color: "white" }}>❌</Text>
          </TouchableOpacity> : null}
        </ImageBackground>
      </ImageBackground>
    );
  }

  return (
    <Camera style={styles.camera} type={type} ref={cameraRef}>
      <canvas ref={canvasRef}></canvas>
      <View
        style={styles.controls}
      >
        <TouchableOpacity
          style={styles.flipCameraButton}
          onPress={() => {
            setType(
              type === Camera.Constants.Type.back
                ? Camera.Constants.Type.front
                : Camera.Constants.Type.back
            );
          }}
        >
          <Text style={styles.flipCameraText}>Flip Camera</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.cameraButton}
          onPress={async () => {
            if (cameraRef.current) {
              const photo = await cameraRef.current.takePictureAsync();
              updatePhoto(photo)
            }
          }}
        >
          <Text style={{ fontSize: 30, padding: 10, color: "white",  }}>Take Picture</Text>
        </TouchableOpacity>
        <Text style={{ fontSize: 14, paddingBottom: 20, color: "white" }}>(Point your camera at something green!)</Text>
      </View>
    </Camera>
  );
}

const styles = StyleSheet.create({
  camera: {
    flex: 1
  },
  cameraButton: {
    alignItems: "center",
    backgroundColor: 'darkorange',
    justifyContent: "center",
    marginBottom: 40,
    borderRadius: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  controls: {
    flex: 1,
    backgroundColor: "transparent",
    alignItems: 'center',
    justifyContent: "flex-end",
  },
  container: {
    flex: 1,
  },
  flipCameraButton: {
    justifyContent: "center",
    backgroundColor: "#666",
    borderRadius: 5,
    marginBottom: 20,
  },
  flipCameraText: {
    fontSize: 10,
    padding: 10,
    fontWeight: 'bold',
    color: "white"
  },
  modalView: {
    backgroundColor: 'rgba(25,25,25,0.6)',
    alignItems: "center",
    height: '100%',
  },
  permissionButton: {
    padding: 16,
    marginTop: 12,
    width: 200
  },
  permissionContainer: {
    backgroundColor: 'darkorange',
    flex: 1,
    justifyContent: "center",
    alignContent: "center"
  },
  permissionText: {
    textAlign: "center",
    fontSize: 24,
    padding: 16
  },
  item: {
    backgroundColor: 'darkorange',
    borderRadius: 10,
    padding: 20,
    marginTop: 12,
    flex: 1,
    justifyContent: 'center',
    alignItems: "center",
    textAlign: 'center',
    marginHorizontal: 16,
  },
  itemText: {
    color: 'white',
    fontSize: 32,
  },
  title: {
    fontSize: 32,
  },
  image: {
    visibility: 'hidden',
    display: 'none'
  }
})
